<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Cookies  </h2>
        <div class="card mb-5">
            <div class="container-fluid">
                <div class="row justify-content-center ">
                    <div class="col-lg-2 col-md-2 col-sm-12">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Domain</h5>
                            <div class="form-group mb-2 mr-sm-2">

                                <ejs-textbox id="domain" cssClass="e-outline" class="e-field" name="domain" placeholder="www.newsandpromotions.com" v-model="filters.domain"></ejs-textbox>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3 col-sm-6 ">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Start Date </h5>
                            <div class="wrap">
                                <ejs-datetimepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.start" ></ejs-datetimepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-6 ">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>End Date </h5>
                            <div class="wrap">
                                <ejs-datetimepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.end" ></ejs-datetimepicker>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3 col-sm-6 ">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Page Size</h5>
                            <div class="wrap">
                                <ejs-dropdownlist cssClass="e-outline" id='sample-list3' :dataSource='pageSizes' :mode='true' v-model="pageSettings.pageSize" placeholder='Page Size' ></ejs-dropdownlist>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tabs-wrap text-center" style="padding-top: 10px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="getDetails(true)">
                                <span class="e-btn-content">SEARCH</span>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <ejs-grid ref="grid" :dataSource="data" :toolbar='toolbar'
                  :toolbarClick='toolbarClick' :allowFiltering="true"
                  :allowExcelExport="true" :allowPdfExport="true"
                  :allowCsvExport="true" :allowSorting="true"
                  :allowGrouping="true"
                  :pageSettings="pageSettings"
                  :groupSettings='groupSettings'
                  :filterSettings="filterSettings"
                  :excelExportComplete='exportComplete'
                  :pdfExportComplete='exportComplete'
                  :csvExportComplete='exportComplete'
                  :actionBegin='actionBegin'>


            <e-columns>

                <e-column headerText="Request Information" :columns="requestColumns" :headerTemplate="'requestTemplate'"></e-column>
            </e-columns>


            <template v-slot:requestTemplate="{ data }">
                <div class="text-center">
                    <span></span>
                    <span class="font-weight-bold">Cookie Acceptance</span>
                </div>
            </template>



            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="capped" format="N" :footerTemplate='footerSum'></e-column>
                        <e-column type="Sum" field="bounce" format="N" :footerTemplate='footerSum'></e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>

        </ejs-grid>
        <ejs-pager :pageSize='pageSettings.pageSize' :pageCount='pageSettings.pageCount'
                   :totalRecordsCount='pageSettings.totalRecordsCount'
                   @click="onPageClick"
        >
        </ejs-pager>


    </div>
</template>


<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment-timezone";
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, ListBoxPlugin,MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";

import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Filter, Group,Toolbar,Aggregate, PagerPlugin } from '@syncfusion/ej2-vue-grids';
// import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
// import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";

Vue.use(PagerPlugin);
Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(ListBoxPlugin);
Vue.use(MultiSelectPlugin)

export default {
    name: "master",
    props: ["user"],
    data: function () {
        return {
            dropdownClients: [],
            dropdownProducts: [],
            dropdownCampaigns: [],
            requestColumns: [
                {
                    field: "date",
                    headerText: "Date",
                    width: 110,
                    minWidth: 10,
                },
                {
                    field: "pathname",
                    headerText: "Domain",
                    width: 190,
                    minWidth: 10,
                },
                {
                    field: "totalAcceptCount",
                    headerText: "Accept",
                    width: 130,
                    minWidth: 15,
                },
                {
                    field: "totalDeclineCount",
                    headerText: "Decline",
                    width: 130,
                    minWidth: 15,
                },
                {
                    field: "totalIgnoreCount",
                    headerText: "Ignore",
                    width: 150,
                    minWidth: 15,
                },
                {
                    field: "total",
                    headerText: "Total",
                    width: 150,
                    minWidth: 15,
                },
                {
                    field: "percentAccept",
                    headerText: "Percentage of Accepted",
                    width: 150,
                    minWidth: 15,
                },
                {
                    field: "percentDecline",
                    headerText: "Percentage of Declined",
                    width: 150,
                    minWidth: 15,
                },
                {
                    field: "percentIgnore",
                    headerText: "Percentage of Ignore",
                    width: 150,
                    minWidth: 15,
                },
            ],

            responseColumns: [
                {
                    field: "Response",
                    headerText: "Pass/Fail",
                    width: 150,
                    minWidth: 5,
                },
                {
                    field: "respDate",
                    headerText: "Date",
                    width: 130,
                    minWidth: 10,
                },
                {
                    field: "respTime",
                    headerText: "Time",
                    width: 130,
                    minWidth: 10,

                },

                {
                    field: "proxyProvider",
                    headerText: "Proxy Provider",
                    width: 150,
                    minWidth: 10,
                },
                {
                    field: "ipAddress",
                    headerText: "IP Address",
                    width: 150,
                    minWidth: 10,
                },
                {
                    field: "ipLocation",
                    headerText: "IP Location",
                    width: 150,
                    minWidth: 10,
                },
                {
                    field: "locationProvider",
                    headerText: "Location Provider",
                    width: 150,
                    minWidth: 10,
                },
            ],
            columns: [

                {field: "date", header: "Date", visible: true, textAlign: "Left",  width: 130},
                {field: "domain", header: "Domain", visible: true, textAlign: "Left", width: 130},
                {field: "totalAcceptCount", header: "Accept", visible: true, textAlign: "Left", width: 130},
                {field: "totalDeclineCount", header: "Decline", visible: true, textAlign: "Left", width: 130},
                {field: "totalIgnoreCount", header: "Ignore", visible: true, textAlign: "Left", width: 130},

                // Add more columns as needed
            ],
            columnOptions: [
                { id: "date", name: "Date" },
                { id: "timezone", name: "Timezone" },
                { id: "domain", name: "Domain" },
                { id: "totalAcceptCount", name: "Accept" },
                { id: "totalDeclineCount", name: "Decline" },
                { id: "totalIgnoreCount", name: "Ignore" },
                // Add more options for other columns
            ],
            selectedColumns: ["date","timezone","domain","accept","decline","ignore"], // Set the initial selected columns
            visibleColumns: ["date","timezone","domain","accept","decline","ignore"],
            selectedPageSize: 50,
            groupSettings:  {
                showDropArea: true,
                disablePageWiseAggregates: true,
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Total: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            pageSizes:[25,50,75],
            filters: {
                view: "City",
                start:moment().startOf('month').startOf('day').toDate(),
                end:  moment().endOf('month').endOf('day').toDate(),
                domain: "",
                client: '',
                product: '',
                campaign:'',
            },
            data: [],
            toolbar: ['CsvExport','ExcelExport'],
            pageSettings: { pageSize: 50 },
            preloader: false,
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
        };
    },
    computed: {
        dfields() {
            return { text: "name", value: "id" };
        },
    },
    created: function () {
        this.$root.preloader = false;
    },
    beforeDestroy() {},

    methods: {
        formatDate(date) {
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            return hours + minutes + seconds;
        },

        exportToExcel() {
            // Get the grid instance using the ref attribute
            const gridInstance = this.$refs.grid.ej2Instances;
            // Trigger the Excel export
            gridInstance.excelExport(this.getExcelExportProperties());
        },
        onPageClick(args) {
            // Update the current page when the user clicks on the pager
            this.filters.page = args.currentPage;
            this.pageSettings.currentPage = args.currentPage;
            this.getDetails(false);
        },

        actionBegin: function(args) {
            if (args.requestType === 'paging') {
                this.filters.page = args.currentPage;
                this.pageSettings.currentPage = args.currentPage;
                this.getDetails(false);
            }
        },
        exportComplete(args) {

            if (args.requestType === 'excelExport' || args.requestType === 'pdfExport') {
                if (args.status === 'success') {
                    // Handle successful export
                } else {
                    console.log(args.error);
                    // Handle export failure or error
                }
            }

            this.$refs.grid.hideSpinner();
        },

        updateColumnVisibility() {
            this.visibleColumns = this.selectedColumns;
        },
        getDetails:function ( count){
            this.$refs.grid.showSpinner();
            this.report = [];
            this.proxieDataSet = {};


            const request = {
                'apikey': this.$root.apikey,
                filters: {
                    start: new Date(),
                    end: new Date()
                }
            };

            request.filters.start = this.filters.start;
            request.filters.end = this.filters.end;
            request.filters.domain = this.filters.domain;
            request.count = count;
            request.pagesize = this.pageSettings.pageSize

            if (this.filters.page) request.filters.page = this.filters.page;

            axios.get(`${this.$root.serverUrl}/admin/reports/cookiesReport`, {params: request}).then(function (resp) {
                //Store the stats
                let count = (resp.data) ? resp.data.Count : 0;
                let data = (resp.data) ? resp.data.Items : [];
                console.log(data,'55555')
                this.total = 0
                this.counterCheck = count
                console.log(count)
                if (resp.data.error) {
                    this.reportLoading = false;
                    swal({title: 'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                } else {
                    // console.log(this.user.timezone)
                    try{
                        data.forEach((op)=>{

                            op.date = moment(op.date).utc().format('YYYY/MM/DD')

                        })
                    }catch (e) {
                        console.log(e)
                    }
                    this.data = data
                    // {result:data, count:count}
                    this.pageSettings.totalRecordsCount = count;
                    this.pageSettings.pageCount = Math.ceil(count / this.pageSettings.pageSize);

                }
                this.$refs.grid.hideSpinner();
                this.searching = false;
                this.$forceUpdate()
            }.bind(this)).catch(() => {
                this.reportLoading = false;
            });
            // }
        },
        toolbarClick: function(args) {

            switch (args.item.text) {

                case 'Excel Export':
                    // this.exportToExcel();
                    this.$refs.grid.excelExport(this.getExcelExportProperties());
                    break;

                case 'PDF Export':
                    this.$refs.grid.pdfExport(this.getPdfExportProperties());
                    break;
                case 'CSV Export':
                    (this.$refs.grid).csvExport(this.getCsvExportProperties());
                    break;
            }
        },

        getExcelExportProperties: function() {
            return {
                fileName: "exported_data.xlsx"
            };
        },
        getCsvExportProperties: function() {
            return {
                fileName: "exported_data.csv"
            };
        },
        getPdfExportProperties: function() {
            return {
                fileName: "exported_data.pdf"
            };
        },
        toDate(item) {
            return moment(item).format('MM-DD-YYYY')
        }
    },
    provide: {
        grid: [Page, Sort, Filter,Group,Toolbar,PdfExport,ExcelExport,Aggregate]
    },
};
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';



.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f0f0f0; /* Set the background color for the sticky headers */
    /* Add any additional custom styles for the sticky headers */
}
</style>
