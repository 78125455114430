<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Privacy Policy Report
        </h2>
        <div class="row">
            <div class="col-lg-2 col-md-3">
                <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                    <h5>Date</h5>
                    <div class="wrap">
                        <ejs-daterangepicker cssClass="e-outline" id='startPicker' v-model="filters.date" ></ejs-daterangepicker>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-6">
                <div class="row">
                    <div class="col-lg-3 col-md-4">
                        <div class="control-styles" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Domain</h5>
                            <ejs-textbox id="domain" cssClass="e-outline" class="e-field" name="domain" placeholder="www.newsandpromotions.com" v-model="filters.domain"></ejs-textbox>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2">
                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="filter">
                                <span class="e-btn-content">SEARCH</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ejs-grid ref="grid" :dataSource="data" :allowPaging="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :query="query"  :allowFiltering="true" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings" :actionFailure='actionFailure' :actionBegin="actionBegin">
            <e-columns>
                <e-column field="date" headerText="Date" text-Align="Left" :format="{type: 'date', skeleton: 'short'}" width="300"></e-column>
                <e-column field="domain" headerText="Domain" text-Align="Left"></e-column>
                <e-column field="total" headerText="Visits" text-Align="Left" width="300"></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment-timezone";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, ListBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";

//import swal from "sweetalert";
import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Group,Toolbar} from '@syncfusion/ej2-vue-grids';
import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(DateRangePickerPlugin);
Vue.use(ListBoxPlugin);

export default {
    name: "clicks",
    props: ["user"],
    data: function () {
        return {
            temp: {
                date: {
                    start: moment().startOf("day").toDate(),
                    end: moment().endOf("day").toDate()
                },
                domain: "",
                ip: ""
            },
            filters: {
                date: {
                    start: moment().startOf("day").toDate(),
                    end: moment().endOf("day").toDate()
                },
                domain: "",
                ip: ""
            },
            data: null,
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 50, pageSizes: true },
            preloader: false,
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' }
        };
    },
    computed: {
        query() {
            let filters = Object.assign({}, this.temp);
            return new Query().addParams('filters', JSON.stringify(filters));
        }
    },
    created: function () {
        this.$root.preloader = false;
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        actionBegin(args) {
            if(args.requestType === "paging") window.scrollTo(0,0);
        },
        actionFailure(args) {
            if (args.error.error.status === 401) this.$auth.logout();
        },
        exportComplete() {
            this.$refs.grid.hideSpinner();
        },
        toolbarClick: function(args = ClickEventArgs) {

            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    this.$refs.grid.showSpinner();
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    this.$refs.grid.showSpinner();
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties: function() {
            return {
                fileName: "qso_export.xlsx"
            };
        },
        getPdfExportProperties: function() {
            return {

                fileName: "qso_export.pdf"
            };
        },
        windowListener: function (e) {
            if (e.keyCode === 13 && !this.processing) {
                // this.load();
            }
        },
        exportToExcel: function () {
            // this.$root.preloader = true;
            //Load the catchers
            var request = Object.assign({ apikey: this.$root.apikey }, this.form);

            this.eLoading = true;
            this.preloader = this.$root.preloader = true;

            return axios
                .get(`${this.$root.serverUrl}/admin/reports/privacy/export`, {
                    params: request,
                    json: true,
                })
                .then(async (resp) => {
                    if (resp == undefined || resp.length == 0) {
                        // this.datasource = { total: 0, data: [] };
                        this.eLoading = false;
                        this.preloader = this.$root.preloader = false;
                    } else {
                        //request = this.serialize(resp);
                        //window.location = `${this.$root.serverUrl}/static/ga/export?${request}`
                        //this.eLoading = false;
                        // this.preloader = this.$root.preloader = false;
                    }
                    this.eLoading = false;
                    this.preloader = this.$root.preloader = false;
                });
        },
        filter() {
            this.$nextTick(function () {
                if (!this.data) {
                    this.data = new DataManager({
                        url: `${this.$root.serverUrl}/admin/reports/privacy`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                    })
                }
                let filters = Object.assign({}, this.filters);
                this.temp = filters;
            })
        }
    },
    provide: {
        grid: [Page, Sort,Group,Toolbar,PdfExport,ExcelExport]
    },
};
</script>

<style>
.e-spin-show {
    background: black;
    opacity: 0.5;
}
.e-spinner-inner {
    top: 20% !important;
}
.e-spin-material {
    height: 60px !important;
    width: 60px !important;
    transform-origin: 30px 30px 30px !important;
}
</style>
