<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Proxies Request Tracking  </h2>
        <div class="card mb-5">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-12">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Client</h5>
                            <div class="form-group mb-2 mr-sm-2">
                                <select v-model="filters.client" class="form-control">
                                    <option value="" selected>-- Any Client --</option>
                                    <option v-for="client in dropdownClients" :key="client.value" :value="client.value">{{ client.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Product</h5>
                            <div class="wrap">
                                <select v-model="filters.product" class="form-control">
                                    <option value="" selected>-- Any Product--</option>
                                    <option v-for="product in dropdownProducts" :key="product.value" :value="product.value">{{ product.text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Campaign</h5>
                            <div class="wrap">
                                <select v-model="filters.campaign" class="form-control">
                                    <option value="" selected>-- Any Campaign--</option>
                                    <option v-for="product in dropdownCampaigns" :key="product.id" :value="product.id">{{ product.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3 col-sm-6 ">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Start Date </h5>
                            <div class="wrap">
                                <ejs-datetimepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.start" ></ejs-datetimepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-6 ">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>End Date </h5>
                            <div class="wrap">
                                <ejs-datetimepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.end" ></ejs-datetimepicker>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3 col-sm-6 ">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Page Size</h5>
                            <div class="wrap">
                                <ejs-dropdownlist cssClass="e-outline" id='sample-list3' :dataSource='pageSizes' :mode='true' v-model="pageSettings.pageSize" placeholder='Page Size' ></ejs-dropdownlist>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tabs-wrap text-center" style="padding-top: 10px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="getDetails(true)">
                                <span class="e-btn-content">SEARCH</span>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <ejs-grid ref="grid" :dataSource="data" :toolbar='toolbar'
                  :toolbarClick='toolbarClick' :allowFiltering="true"
                  :allowExcelExport="true" :allowPdfExport="true"
                  :allowCsvExport="true" :allowSorting="true"
                  :allowGrouping="true"
                  :pageSettings="pageSettings"
                  :groupSettings='groupSettings'
                  :filterSettings="filterSettings"
                  :excelExportComplete='exportComplete'
                  :pdfExportComplete='exportComplete'
                  :csvExportComplete='exportComplete'
                  :actionBegin='actionBegin'>

            <!--            <e-columns>-->

            <!--                <e-column field="domain" headerText="Domain" text-Align="Left"  :visible="visibleColumns.includes('domain')"  ></e-column>-->
            <!--                <e-column field="country" headerText="Country" text-Align="Left" width="100" :visible="visibleColumns.includes('country')" ></e-column>-->
            <!--                <e-column field="state" headerText="State" text-Align="Left" width="100" :visible="visibleColumns.includes('state')" ></e-column>-->
            <!--                <e-column field="tos" headerText="Avg TOS (s)" text-Align="Left" format="N" :visible="visibleColumns.includes('tos')" ></e-column>-->
            <!--                <e-column field="shown" headerText="Shown" text-Align="Left" format="N" :visible="visibleColumns.includes('shown')" ></e-column>-->
            <!--                <e-column field="capped" headerText="Capped" text-Align="Left" format="N" :visible="visibleColumns.includes('capped')" ></e-column>-->
            <!--                <e-column field="bounce" headerText="Bounce" text-Align="Left" format="N" :visible="visibleColumns.includes('bounce')" ></e-column>-->
            <!--                <e-column field="clicks" headerText="Total Clicks" text-Align="Left" :visible="visibleColumns.includes('clicks')" ></e-column>-->
            <!--            </e-columns>-->

            <e-columns>

                <e-column headerText="Request Information" :columns="requestColumns" :headerTemplate="'requestTemplate'"></e-column>
                <e-column headerText="Response Information" :columns="responseColumns" :headerTemplate="'responseTemplate'"></e-column>
            </e-columns>


            <template v-slot:requestTemplate="{ data }">
                <div class="text-center">
                    <span></span>
                    <span class="font-weight-bold">Request Information</span>
                </div>
            </template>
            <template v-slot:responseTemplate="{ data }">
                <div class="text-center">
                    <span></span>
                    <span class="font-weight-bold">Response Information</span>
                </div>
            </template>


            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="capped" format="N" :footerTemplate='footerSum'></e-column>
                        <e-column type="Sum" field="bounce" format="N" :footerTemplate='footerSum'></e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>

        </ejs-grid>
        <ejs-pager :pageSize='pageSettings.pageSize' :pageCount='pageSettings.pageCount'
                   :totalRecordsCount='pageSettings.totalRecordsCount'
                   @click="onPageClick"
        >
        </ejs-pager>


    </div>
</template>


<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment-timezone";
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, ListBoxPlugin,MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";

import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Filter, Group,Toolbar,Aggregate, PagerPlugin } from '@syncfusion/ej2-vue-grids';
// import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
// import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";

Vue.use(PagerPlugin);
Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(ListBoxPlugin);
Vue.use(MultiSelectPlugin)

export default {
    name: "master",
    props: ["user"],
    data: function () {
        return {
            dropdownClients: [],
            dropdownProducts: [],
            dropdownCampaigns: [],
            requestColumns: [
                {
                    field: "date",
                    headerText: "Date",
                    width: 130,
                    textAlign: "Right",
                    minWidth: 10,
                },
                {
                    field: "time",
                    headerText: "Time",
                    width: 130,
                    textAlign: "Right",
                    minWidth: 10,

                },
                {
                    field: "timezone",
                    headerText: "Time Zone",
                    width: 150,
                    minWidth: 10,
                },
                {
                    field: "proxyProvider",
                    headerText: "Proxy Provider",
                    width: 150,
                    minWidth: 10,
                },
                {
                    field: "ServiceType",
                    headerText: "Service Type",
                    width: 150,
                    minWidth: 15,
                },
                {
                    field: "geoRequestedParsed",
                    headerText: "Geo Requested",
                    width: 150,
                    minWidth: 15,
                },
            ],

            responseColumns: [
                {
                    field: "Response",
                    headerText: "Pass/Fail",
                    width: 150,
                    minWidth: 5,
                },
                {
                    field: "respDate",
                    headerText: "Date",
                    width: 130,
                    minWidth: 10,
                },
                {
                    field: "respTime",
                    headerText: "Time",
                    width: 130,
                    minWidth: 10,

                },

                {
                    field: "proxyProvider",
                    headerText: "Proxy Provider",
                    width: 150,
                    minWidth: 10,
                },
                {
                    field: "ipAddress",
                    headerText: "IP Address",
                    width: 150,
                    minWidth: 10,
                },
                {
                    field: "ipLocation",
                    headerText: "IP Location",
                    width: 150,
                    minWidth: 10,
                },
                {
                    field: "locationProvider",
                    headerText: "Location Provider",
                    width: 150,
                    minWidth: 10,
                },
            ],
            columns: [
                {field: "Response", header: "Pass/Fail", visible: true, textAlign: "Left", format: " ", width: " "},
                {field: "proxyProvider", header: "Proxy Provider", visible: true, textAlign: "Left", format: " ", width: ""},
                {field: "ProxyProvider", header: "Proxy Provider", visible: true, textAlign: "Left", format: " ", width: ""},
                {field: "ipAddress", header: "IP Address", visible: true, textAlign: "Left", format: "N", width: ""},
                {field: "iplocation", header: "IP Location", visible: true, textAlign: "Left", format: "N", width: ""},
                {field: "locationProvider", header: "Location Provider", visible: true, textAlign: "Left", format: "N", width: ""},
                {field: "date", header: "Date", visible: true, textAlign: "Right",  width: 130},
                {field: "date", header: "Time", visible: true, textAlign: "Right", width: 130},
                {field: "timezone", header: "Time Zone", visible: true, textAlign: "Left", format: " ", width: ""},
                {field: "ServiceType", header: "Service Type", visible: true, textAlign: "Left", format: "N", width: ""}

                // Add more columns as needed
            ],
            columnOptions: [
                { id: "Response", name: "Response" },
                { id: "proxyProvider", name: "ProxyProvider" },
                { id: "ipAddress", name: "IpAddress" },
                { id: "locationProvider", name: "LocationProvider" },
                { id: "ProxyProvider", name: "ProxyProvider" },
                { id: "date", name: "Date" },
                { id: "timezone", name: "Timezone" },
                { id: "serviceType", name: "ServiceType" },
                // Add more options for other columns
            ],
            selectedColumns: ["Response",'proxyProvider','ipAddress','locationProvider','ProxyProvider','date','timezone','serviceType'], // Set the initial selected columns
            visibleColumns: ["Response",'proxyProvider','ipAddress','locationProvider','ProxyProvider','date','timezone','serviceType'],
            selectedPageSize: 50,
            groupSettings:  {
                showDropArea: true,
                disablePageWiseAggregates: true,
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Total: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            pageSizes:[25,50,75],
            filters: {
                view: "City",
                start:moment().startOf('month').startOf('day').toDate(),
                end:  moment().endOf('month').endOf('day').toDate(),
                domain: "",
                client: '',
                product: '',
                campaign:'',
            },
            data: [],
            toolbar: ['CsvExport','ExcelExport'],
            pageSettings: { pageSize: 50 },
            preloader: false,
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
        };
    },
    computed: {
        dfields() {
            return { text: "name", value: "id" };
        },
    },
    created: function () {
        this.$root.preloader = false;
        this.GetClientsList();
        this.GetProductList();
        this.GetCampaignList();

    },
    beforeDestroy() {},

    methods: {
        formatDate(date) {
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            return hours + minutes + seconds;
        },
        GetClientsList: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/client`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClients = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        GetProductList: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/product/crawler`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownProducts = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        GetCampaignList: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/product/camapigns`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownCampaigns = (resp.data && resp.data.campaigns)? resp.data.campaigns : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        exportToExcel() {
            // Get the grid instance using the ref attribute
            const gridInstance = this.$refs.grid.ej2Instances;
            // Trigger the Excel export
            gridInstance.excelExport(this.getExcelExportProperties());
        },
        onPageClick(args) {
            // Update the current page when the user clicks on the pager
            this.filters.page = args.currentPage;
            this.pageSettings.currentPage = args.currentPage;
            this.getDetails(false);
        },

        actionBegin: function(args) {
            if (args.requestType === 'paging') {
                this.filters.page = args.currentPage;
                this.pageSettings.currentPage = args.currentPage;
                this.getDetails(false);
            }
        },
        exportComplete(args) {

            if (args.requestType === 'excelExport' || args.requestType === 'pdfExport') {
                if (args.status === 'success') {
                    // Handle successful export
                } else {
                    console.log(args.error);
                    // Handle export failure or error
                }
            }

            this.$refs.grid.hideSpinner();
        },

        updateColumnVisibility() {
            this.visibleColumns = this.selectedColumns;
        },
        getDetails:function ( count){
            this.$refs.grid.showSpinner();
            this.report = [];
            this.proxieDataSet = {};


            const request = {
                'apikey': this.$root.apikey,
                filters: {
                    start: new Date(),
                    end: new Date()
                }
            };

            request.filters.start = this.filters.start;
            request.filters.end = this.filters.end;
            request.filters.client = this.filters.client;
            request.filters.product = this.filters.product;
            request.filters.campaign = this.filters.campaign;
            request.count = count;
            request.pagesize = this.pageSettings.pageSize

            if (this.filters.page) request.filters.page = this.filters.page;

            axios.get(`${this.$root.serverUrl}/admin/proxies/request`, {params: request}).then(function (resp) {
                //Store the stats
                let count = (resp.data) ? resp.data.Count : 0;
                let data = (resp.data) ? resp.data.Items : [];
                console.log(data,'55555')
                this.total = 0
                this.counterCheck = count
                console.log(count)
                if (resp.data.error) {
                    this.reportLoading = false;
                    swal({title: 'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                } else {
                    // console.log(this.user.timezone)
                    try{
                        data.forEach((op)=>{
                            op.time = moment(op.date).tz(this.user.timezone || 'America/New_York').format('LTS')
                            op.date = moment(op.date).format('YYYY/MM/DD')
                            op.respTime = moment(op.respDate).tz(this.user.timezone || 'America/New_York').format('LTS')
                            op.respDate = moment(op.respDate).format('YYYY/MM/DD')
                        })
                    }catch (e) {
                        console.log(e)
                    }
                    this.data = data
                    // {result:data, count:count}
                    this.pageSettings.totalRecordsCount = count;
                    this.pageSettings.pageCount = Math.ceil(count / this.pageSettings.pageSize);

                }
                this.$refs.grid.hideSpinner();
                this.searching = false;
                this.$forceUpdate()
            }.bind(this)).catch(() => {
                this.reportLoading = false;
            });
            // }
        },
        toolbarClick: function(args) {

            switch (args.item.text) {

                case 'Excel Export':
                    // this.exportToExcel();
                    this.$refs.grid.excelExport(this.getExcelExportProperties());
                    break;

                case 'PDF Export':
                    this.$refs.grid.pdfExport(this.getPdfExportProperties());
                    break;
                case 'CSV Export':
                    (this.$refs.grid).csvExport(this.getCsvExportProperties());
                    break;
            }
        },

        getExcelExportProperties: function() {
            return {
                fileName: "exported_data.xlsx"
            };
        },
        getCsvExportProperties: function() {
            return {
                fileName: "exported_data.csv"
            };
        },
        getPdfExportProperties: function() {
            return {
                fileName: "exported_data.pdf"
            };
        },
        toDate(item) {
            return moment(item).format('MM-DD-YYYY')
        }
    },
    provide: {
        grid: [Page, Sort, Filter,Group,Toolbar,PdfExport,ExcelExport,Aggregate]
    },
};
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';



.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f0f0f0; /* Set the background color for the sticky headers */
    /* Add any additional custom styles for the sticky headers */
}
</style>
